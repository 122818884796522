import { makeVar, useReactiveVar } from '@apollo/client';
import { USER_SESSIONS_LOCAL_REF } from '../../components/_core/OfflineHandler/offline-handler.container';
import { IUserSessionStore, UserActionType } from '../types';
import { store } from './storage';
import { IRecordCompositeScoreInput, IRecordScoreInput, IRecordUserActionsResults } from '../../components/Score/ManageEvaluations/manage-evaluations.interface';
import _ from 'lodash';

export const currUserSessionId = makeVar<String>("");
export const getCurrUserSessionId = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useReactiveVar(currUserSessionId);
};

// Create and track new user session
export const recordUserSession = async (user: IUser) => {
    const { userSessions } = JSON.parse((await store.get(USER_SESSIONS_LOCAL_REF)) || '{"userSessions":{}}') as IUserSessionStore

    if (user) {
        const newSession: IUserSession = {
            startedAt: new Date(),
            actions: []
        }

        // Filter out any old sessions for current user that don't have any logged actions
        const newUserSessions: IUserSession[] = []
        if (user._id in userSessions) newUserSessions.push(...userSessions[user._id].filter((curr) => !!curr.actions?.length))
        newUserSessions.push(newSession)
        userSessions[user._id] = newUserSessions

        await store.set(USER_SESSIONS_LOCAL_REF, JSON.stringify({userSessions}))
    }
}

export const recordUserSessionActions = async (user: IUser, actions: IUserAction[]) => {
    const { userSessions } = JSON.parse((await store.get(USER_SESSIONS_LOCAL_REF)) || '{"userSessions":{}}') as IUserSessionStore

    if (user?._id && user._id in userSessions) {
        const currUserSessions = userSessions[user._id]
        if (currUserSessions.length) {
            currUserSessions[currUserSessions.length - 1].actions?.push(...actions)
            userSessions[user._id] = currUserSessions

            await store.set(USER_SESSIONS_LOCAL_REF, JSON.stringify({userSessions}))
        }
    }
}

// Remove all action from stored user sessions matching the provided session and actions
export const removeUserSessionActions = async (user: IUser, userSession: IUserSession, actions: IUserAction[]) => {
    const { userSessions } = JSON.parse((await store.get(USER_SESSIONS_LOCAL_REF)) || '{"userSessions":{}}') as IUserSessionStore

    if (user._id in userSessions) {
        const currUserSessions = userSessions[user._id]
        const sessionIndex = currUserSessions.findIndex(s => s.startedAt === userSession.startedAt)
        if (sessionIndex > -1) {
            // Filter out matching user actions
            currUserSessions[sessionIndex].actions = currUserSessions[sessionIndex].actions.filter(a => !actions.find(successfulA => successfulA.actionType === a.actionType && new Date(successfulA.loggedAt).getTime() === new Date(a.loggedAt).getTime()))
            userSessions[user._id] = currUserSessions

            await store.set(USER_SESSIONS_LOCAL_REF, JSON.stringify({userSessions}))
        }
    }
}

// Get the newest user session for curr user
export const getCurrUserSession = async (user: IUser) => {
    const { userSessions } = JSON.parse((await store.get(USER_SESSIONS_LOCAL_REF)) || '{"userSessions":{}}') as IUserSessionStore

    if (user?._id && user._id in userSessions) {
        const currUserSessions = userSessions[user._id]
        return currUserSessions[currUserSessions.length - 1]
    }
}

// Get all sessions for user, sorted by oldest first
export const getAllCurrUsersSessions = async (user: IUser) => { 
    const { userSessions } = JSON.parse((await store.get(USER_SESSIONS_LOCAL_REF)) || '{"userSessions":{}}') as IUserSessionStore

    if (user._id in userSessions) {
        const currUserSessions = userSessions[user._id]
        return currUserSessions.sort((a, b) => new Date(a.startedAt).getTime() - new Date(b.startedAt).getTime())
    }
}

export const setCurrUserSessions = async (user: IUser, sessions: IUserSession[]) => { 
    const { userSessions } = JSON.parse((await store.get(USER_SESSIONS_LOCAL_REF)) || '{"userSessions":{}}') as IUserSessionStore
    userSessions[user._id] = sessions
    await store.set(USER_SESSIONS_LOCAL_REF, JSON.stringify({userSessions}))
}

export const transformScoresToUserActions = (scores: IRecordScoreInput[], compositeScores: IRecordCompositeScoreInput[]): IUserAction[] => {
  const partialScores = scores.map((score) => _.omit(score, ['__typename']))
  const partialCompositeScores = compositeScores.map(score => _.omit(score, ['__typename']));
  return [
    ...partialScores.map(({remove, loggedAt, ...score}) => {
      return {actionType: remove ? UserActionType.removeScore : UserActionType.score, payload: {score}, loggedAt} as IUserAction
    }),
    ...partialCompositeScores.map(({remove, loggedAt, ...score}) => {
      return {actionType: remove ? UserActionType.removeCompositeScore : UserActionType.compositeScore, payload: {score}, loggedAt} as IUserAction
    })
  ]
}

// Loop through recordUserSession results and determine what score operations were successful or not
export const handleSessionActionResults = ({ results, scoreOperations, compositeScoreOperations } : { results?: IRecordUserActionsResults; scoreOperations: IRecordScoreInput[]; compositeScoreOperations: IRecordCompositeScoreInput[] }) => {
    // Score are processed before composite scores
    const scoreResults = scoreOperations.reduce((acc: {succeeded: IRecordScoreInput[]; failed: IRecordScoreInput[]}, curr, i) => {
        if(!results?.recordUserSession || results.recordUserSession.length <= i || !results.recordUserSession[i]?.succeeded) acc.failed.push(curr)
        else acc.succeeded.push(curr)
        return acc
    }, {succeeded: [], failed: []})

    const compositeScoreResults = compositeScoreOperations.reduce((acc: {succeeded: IRecordCompositeScoreInput[]; failed: IRecordCompositeScoreInput[]}, curr, i) => {
        if(!results?.recordUserSession || results.recordUserSession.length <= i + scoreOperations.length || !results.recordUserSession[i + scoreOperations.length]?.succeeded) acc.failed.push(curr)
        else acc.succeeded.push(curr)
        return acc
    }, {succeeded: [], failed: []})

    if ((scoreResults.succeeded.length || compositeScoreResults.succeeded.length) && results?.user) removeUserSessionActions(results.user, results?.userSession, transformScoresToUserActions(scoreResults.succeeded, compositeScoreResults.succeeded))

    return {scoreResults, compositeScoreResults}
}