import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import ManageEvaluations from './manage-evaluations.component';
import { groupByPosition, sortByJersey } from './manage-evaluations.helpers';
import { IFormOption } from '../../_core/_ui/forms.component';
import { IRecordCompositeScoreInput, IRecordScoreInput, IRecordUserActionsResults } from './manage-evaluations.interface';
import { getQueueOpen } from '../../_core/PersistGateContainer/persist-gate.container';
import { getCompanySport } from '../../../lib/services/company.service';
import uuid from 'uuid';
import { getAuthUser } from '../../../lib/services/auth.service';
import { handleSessionActionResults, recordUserSessionActions, removeUserSessionActions, transformScoresToUserActions } from '../../../lib/utils/user-session.service';

interface IManageEvaluationsContainer {
  calibrationVideos: ICalibrationVideo[];
  assessmentSession?: IAssessmentSession;
  drills: IDrill[];
  players: IPlayerAssessment[];
  jerseys: { [key: string]: IJersey };
  scores: IScore[];
  compositeScores: ICompositeScore[];
  loading: boolean;
  fetchError: boolean;
  member?: IMember;
  submitScores: (_: IRecordScoreInput[], _c: IRecordCompositeScoreInput[]) => Promise<IRecordUserActionsResults | undefined>
  hasUnsavedScores: boolean;
  setHasUnsavedScores: Function;
  isSaving: boolean;
}

const ManageEvaluationsContainer: React.FC<IManageEvaluationsContainer> = ({
  calibrationVideos,
  assessmentSession,
  drills,
  players,
  jerseys,
  scores,
  compositeScores,
  loading,
  fetchError,
  member,
  submitScores,
  hasUnsavedScores,
  setHasUnsavedScores,
  isSaving
}) => {
  const user = getAuthUser()

  const isQueueOpen = getQueueOpen();
  const [showReview, setShowReview] = useState(false);
  const [scoreHash, setScoreHash] = useState({});
  const [scoreOperations, setScoreOperations] = useState<IRecordScoreInput[]>([]);
  const [compositeScoreOperations, setCompositeScoreOperations] = useState<IRecordCompositeScoreInput[]>([]);
  const [compositeScoreHash, setCompositeScoreHash] = useState<{ [key: string]: { [key: string]: { [key: string]: { [key: number]: ICompositeScore } } } }>({});
  const [chosenVideos, setChosenVideos] = useState<{ [key: string]: { [key: string]: { [key: number]: ICalibrationVideo } } }>({});
  const [activeRound, setActiveRound] = useState(1);
  const [activeDrill, setActiveDrill] = useState<IDrill | undefined>();
  const [drillsById, setDrillsById] = useState<{ [key: string]: IDrill }>({});
  const [drillOptions, setDrillOptions] = useState<IFormOption[]>([]);
  const [activePlayer, setActivePlayer] = useState<IPlayerAssessment | undefined>();
  const [playersByPosition, setPlayersByPosition] = useState({});
  const [playerOptions, setPlayerOptions] = useState<IFormOption[]>([]);
  const [playersById, setPlayersById] = useState<{ [key: string]: IPlayerAssessment }>({});
  const [playersLinear, setPlayersLinear] = useState<IPlayerAssessment[]>([]);
  const [currPlayerIndex, setCurrPlayerIndex] = useState(0);
  const [stateLoaded, setStateLoaded] = useState(false);
  const [hasSaveError, setHasSaveError] = useState(false);
  const [isComposite, setIsComposite] = useState(false);
  const [activeComposite, setActiveComposite] = useState<number>();
  const [hasCalibrationVideos, setHasCalibrationVideos] = useState(false);
  const numJerseys = _.keys(jerseys).length;

  const sport = getCompanySport()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (players.length && numJerseys > 0) {
      const nextPlayersByPosition = groupByPosition(players, sport);
      _.each(nextPlayersByPosition, (players, position) => {
        nextPlayersByPosition[position] = sortByJersey(nextPlayersByPosition[position], jerseys);
      });

      const positions = _.keys(nextPlayersByPosition);
      const firstPlayer = _.get(nextPlayersByPosition, [positions[0], '0']);
      if (firstPlayer) {
        setActivePlayer(firstPlayer);
      }
      setPlayersByPosition(nextPlayersByPosition);
      setPlayersById(_.keyBy(players, '_id'))

      const nextPlayerOptions: any[] = [];
      _.each(nextPlayersByPosition, (players, position) => {
        nextPlayerOptions.push({
          label: position,
          options: _.map(players, player => ({ value: player._id, label: `${_.capitalize(jerseys[player._id]?.color)} - ${jerseys[player._id]?.number}`}))
        });
      });
      setPlayerOptions(nextPlayerOptions);

      setPlayersLinear(_.flatten(_.values(nextPlayersByPosition)));

      setStateLoaded(true);
    }
  }, [players.length, numJerseys]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const nextScoreHash = {};

    _.each(scores, score => {
      _.set(nextScoreHash, [score.playerAssessmentId, score.drillId, score.skillId, score.round], score);
    });

    setScoreHash(nextScoreHash);
  }, [scores.length]);

  useEffect(() => {
    const nextScoreHash = {};

    _.each(compositeScores, score => {
      _.set(nextScoreHash, [score.compositeIndex, score.drillId, score.skillId, score.round], score);
    });

    setCompositeScoreHash(nextScoreHash);
  }, [compositeScores.length]);

  const handleSetActiveDrill = useCallback((drill: IDrill) => {
    setActiveDrill(drill);
    const hasDrillVideos = _.filter(calibrationVideos, video => video.drillId === drill._id).length > 0;

    setHasCalibrationVideos(hasDrillVideos);
    if (!hasDrillVideos) setIsComposite(false);
  }, [calibrationVideos]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (drills.length) {
      const nextDrillOptions: IFormOption[] = drills.map(drill => ({
        value: drill._id,
        label: drill.name
      }));

      setDrillsById(_.keyBy(drills, '_id'));
      setDrillOptions(nextDrillOptions);
      handleSetActiveDrill(drills[0]);
    }
  }, [assessmentSession, drills.length, handleSetActiveDrill]);

  const setChosenVideo = (activeComposite: number, drillId: string, round: number, video: ICalibrationVideo) => {
    const nextChosenVideos = { ...chosenVideos };

    _.set(nextChosenVideos, [String(activeComposite), drillId, round], video);

    setChosenVideos(nextChosenVideos);
  };

  const handleGetCompositeScoreStatus = (compositeIndex: number, drillId: string, round: number) => {
    let currSkillCount = 0;
    // [score.compositeIndex, score.drillId, score.skillId, score.round], score);

    const relevantVideos = calibrationVideos.filter((video) => video.drillId === drillId);
    const relevantSkills = relevantVideos.map((video) => video.skill);
    const expectedSkillCount = _.uniqBy(relevantSkills, '_id').length;

    const compositeScores = _.get(compositeScoreHash, [compositeIndex, drillId], {});
    _.each(compositeScores, (roundScores, skillId) => {
      if (roundScores && roundScores[activeRound]) {
        currSkillCount += 1;
      }
    });

    if (currSkillCount === expectedSkillCount) return 'FILLED';
    if (currSkillCount > 0) return 'PARTIAL';
    return 'NONE';
  };

  const handleGetScoreStatus = (playerAssessment: IPlayerAssessment, drillId: string, round: number) => {
    let expectedSkillCount = 0;
    const activeSkillIds: string[] = []
    _.each(drillsById[drillId].skillSets, (skillSet => {
      if (_.includes(skillSet.positionIds, playerAssessment.position._id)) {
        _.each(skillSet.skills, skill => {
          if (!skill.deletedAt) {
            expectedSkillCount += 1;
            activeSkillIds.push(skill._id)
          }
        })
      }
    }))
    const skillScores = _.get(scoreHash, [playerAssessment._id, drillId], {});
    let currSkillCount = 0;

    _.each(skillScores, (roundScores, skillId): void => {
      if (roundScores && roundScores[activeRound] && activeSkillIds.includes(skillId)) {
        currSkillCount += 1;
      }
    });

    if (currSkillCount === expectedSkillCount) return 'FILLED';
    if (currSkillCount > 0) return 'PARTIAL';
    return 'NONE';
  };

  const handleSetActiveComposite = (index: number) => {
    setIsComposite(true);
    setActiveComposite(index);
  };

  const handleSetActivePlayer = (player: IPlayerAssessment) => {
    setIsComposite(false);
    setCurrPlayerIndex(_.findIndex(playersLinear, currPlayer => currPlayer._id === player._id));
    setActivePlayer(player);
  }

  /*
   * Score operations are stored in lists in created order.
   * These operations are polled at intervals (or on demand), and are submitted as score actions to save/store for later upload.
   *
   * Scores are still stored for UX evaluation purposes in a score hash. So score updates should be performed against both fields.
   */
  const handleRemoveCompositeScore = (score: IRecordCompositeScoreInput) => {
    const nextScoreHash = { ...compositeScoreHash };
    _.unset(nextScoreHash, [score.compositeIndex, score.drillId, score.skillId, score.round]);
    setCompositeScoreHash(nextScoreHash);

    setCompositeScoreOperations(curr => [...curr, {...score, loggedAt: new Date(), remove: true}]);
    setHasUnsavedScores(true);
  };

  const handleRecordCompositeScore = (score: IRecordCompositeScoreInput) => {
    const loggedAt = new Date();
    const clientId = score.clientId ?? loggedAt.getTime() + "-" + user?._id + "-" + uuid.v4();

    const nextScoreHash = { ...compositeScoreHash };
    _.set(nextScoreHash, [score.compositeIndex, score.drillId, score.skillId, score.round], {...score, loggedAt, clientId});
    setCompositeScoreHash(nextScoreHash);

    setCompositeScoreOperations(curr => [...curr, {...score, loggedAt, clientId}]);
    setHasUnsavedScores(true);

  };

  const handleRemoveScore = (score: IRecordScoreInput) => {
    const nextScoreHash = { ...scoreHash };
    _.unset(nextScoreHash, [score.playerAssessmentId, score.drillId, score.skillId, score.round]);
    setScoreHash(nextScoreHash);

    setScoreOperations(curr => [...curr, {...score, loggedAt: new Date(), remove: true}]);
    setHasUnsavedScores(true);
  };

  const handleRecordScore = (score: IRecordScoreInput) => {
    const loggedAt = new Date();
    const clientId = score.clientId ?? loggedAt.getTime() + "-" + user?._id + "-" + uuid.v4();

    const nextScoreHash = { ...scoreHash };
    _.set(nextScoreHash, [score.playerAssessmentId, score.drillId, score.skillId, score.round], {...score, loggedAt, clientId});
    setScoreHash(nextScoreHash);

    setScoreOperations(curr => [...curr, {...score, loggedAt, clientId}]);
    setHasUnsavedScores(true);
  }

  const handleSubmitScores = useCallback(() => {
    if (isSaving) return;

    setHasUnsavedScores(false)
    return submitScores(scoreOperations, compositeScoreOperations)
      .then(async (results) => {
        let failed = false
        if (results) {
          // Receive results of each score operation in latest submit, and then filter out successful score operations from local storage and from the latest score operations.
          const processedResults = await handleSessionActionResults({results, scoreOperations, compositeScoreOperations})
          setScoreOperations(ops =>  ops.filter((op) => !scoreOperations.includes(op) || processedResults.scoreResults.failed.includes(op)))
          setCompositeScoreOperations(ops => ops.filter((op) => !compositeScoreOperations.includes(op) || processedResults.compositeScoreResults.failed.includes(op)))

          failed = !!processedResults.scoreResults.failed.length || !!processedResults.compositeScoreResults.failed.length
        } else {
          failed = true
        }
 
        setHasSaveError(failed);

        if (failed) setTimeout(() => {
            setHasSaveError(false);
          }, 3000);
      })
      .catch(() => {
        setHasUnsavedScores(true);

        setHasSaveError(true);
        setTimeout(() => {
          setHasSaveError(false);
        }, 3000);
      });
  }, [scoreOperations, compositeScoreOperations]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (hasUnsavedScores && !isSaving && assessmentSession && user) {
        const storeScores = async () => {
          if (hasUnsavedScores && !isSaving) {
            const scoreOperationsSnapshot = [...scoreOperations]
            const compositeScoreOperationsSnapshot = [...compositeScoreOperations]
            setHasUnsavedScores(false)
            if (isQueueOpen) {
              handleSubmitScores()
            } else {
              // If queue is not open then store actions in local storage and wipe current operations on success
              try {
                recordUserSessionActions(
                  user, 
                  transformScoresToUserActions(scoreOperations, compositeScoreOperations)
                )

                setScoreOperations(ops =>  ops.filter((op) => !scoreOperationsSnapshot.includes(op)))
                setCompositeScoreOperations(ops => ops.filter((op) => !compositeScoreOperationsSnapshot.includes(op)))
              } catch (e) {
                console.error("Failed to record score operations", e);
              }
            }
          }
        }

        storeScores();
      }
    }, 5 * 1000);
    return () => clearInterval(interval);
  }, [hasUnsavedScores, isSaving, scoreOperations, compositeScoreOperations, handleSubmitScores]);

  const canCheckIn = (member && _.includes(member.roles, 'CHECK_IN')) || false;

  return <ManageEvaluations
    calibrationVideos={calibrationVideos}
    loading={loading || !stateLoaded}
    fetchError={fetchError}
    session={assessmentSession}
    jerseys={jerseys}
    removeScore={handleRemoveScore}
    recordScore={handleRecordScore}
    submitScores={handleSubmitScores}
    playersByPosition={playersByPosition}
    playersById={playersById}
    playersLinear={playersLinear}
    currPlayerIndex={currPlayerIndex}
    setCurrPlayerIndex={setCurrPlayerIndex}
    playerOptions={playerOptions}
    drills={drills}
    drillsById={drillsById}
    drillOptions={drillOptions}
    hasCalibrationVideos={hasCalibrationVideos}
    activePlayer={activePlayer}
    isComposite={isComposite}
    activeComposite={activeComposite}
    setActiveComposite={handleSetActiveComposite}
    setActivePlayer={handleSetActivePlayer}
    activeDrill={activeDrill}
    setActiveDrill={handleSetActiveDrill}
    activeRound={activeRound}
    setActiveRound={setActiveRound}
    getScoreStatus={handleGetScoreStatus}
    getCompositeScoreStatus={handleGetCompositeScoreStatus}
    scoreHash={scoreHash}
    compositeScoreHash={compositeScoreHash}
    showReview={showReview}
    setShowReview={setShowReview}
    canCheckIn={canCheckIn}
    hasUnsavedScores={hasUnsavedScores}
    hasSaveError={hasSaveError}
    isSaving={isSaving}
    setChosenVideo={setChosenVideo}
    chosenVideos={chosenVideos}
    recordCompositeScore={handleRecordCompositeScore}
    removeCompositeScore={handleRemoveCompositeScore}
  />
};

export default ManageEvaluationsContainer;
