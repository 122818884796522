import _ from 'lodash';
import { defineMessages } from 'react-intl';
import aOrAn from 'indefinite';
import { SportTermLookup, SportTerminology } from '../../../lib/types';
import { pluralTerm } from '../../../lib/helpers/term.helper';

export const scope = 'app.components.UpdateCompanyForm';

export default (termLookup: SportTermLookup) => {
  const athleteTerm = termLookup[SportTerminology.ATHLETE].toLowerCase();
  const sessionTerm = termLookup[SportTerminology.SESSION].toLowerCase();
  return defineMessages({
    lblCompanyWebsite: {
      id: `${scope}.lblCompanyWebsite`,
      defaultMessage: 'Company Website'
    },
    lblCompanyName: {
      id: `${scope}.lblCompanyName`,
      defaultMessage: 'Association Name',
    },
    lblCommunicationEmail: {
      id: `${scope}.lblCommunicationEmail`,
      defaultMessage: `Email to provide for ${pluralTerm(athleteTerm)} to reply-to:`
    },
    lblTimezone: {
      id: `${scope}.lblTimezone`,
      defaultMessage: 'Timezone'
    },
    lblLevelAssignmentCopy: {
      id: `${scope}.lblLevelAssignmentCopy`,
      defaultMessage: `When you notify a ${athleteTerm} about their assigned level:`
    },
    lblTeamAssignmentCopy: {
      id: `${scope}.lblTeamAssignmentCopy`,
      defaultMessage: `When you notify a ${athleteTerm} about their assigned team:`
    },
    lblEvaluationsConcludedCopy: {
      id: `${scope}.lblEvaluationsConcludedCopy`,
      defaultMessage: `When a ${athleteTerm} has finished their scheduled evaluations:`,
    },
    lblReportCardCopy: {
      id: `${scope}.lblReportCardCopy`,
      defaultMessage: `When a ${athleteTerm} is send their report card:`,
    },
    lblHoldPlayerCopy: {
      id: `${scope}.lblHoldPlayerCopy`,
      defaultMessage: `When a ${athleteTerm} is put on hold during evaluation scheduling:`
    },
    lblIceTimeCancelledCopy: {

      id: `${scope}.lblHoldPlayerCopy`,
      defaultMessage: `When ${aOrAn(sessionTerm)} is cancelled:`
    },
    lblIceTimeUpdateCopy: {
      id: `${scope}.lblIceTimeUpdateCopy`,
      defaultMessage: `When ${aOrAn(sessionTerm)} is updated:`,
    },
    lblIceTimeReminderCopy: {
      id: `${scope}.lblIceTimeReminderCopy`,
      defaultMessage: `When sending a reminder about a scheduled ${sessionTerm}:`,
    },
    lblIceTimeStartCopy: {
      id: `${scope}.lblIceTimeStartCopy`,
      defaultMessage: `When initially notifying a ${athleteTerm} about a scheduled ${sessionTerm}:`,
    },
    lblWelcomeCopy: {
      id: `${scope}.lblWelcomeCopy`,
      defaultMessage: 'When you send out a welcome email for the assessment:'
    }
  })
};
