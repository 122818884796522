import React from 'react';
import { gql, useQuery } from '@apollo/client';

// Material UI
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

// Data
import { COMMUNICATION_FIELDS } from '../../../lib/queries/email-log.queries';
import dayjs from 'dayjs';
import { getCompanySportTerminology } from '../../../lib/services/company.service';
import { SportTerminology } from '../../../lib/types';

const GET_COMMUNICATION_LIST = gql`
  query GetCommunicationList($playerAssessmentId: ID!) {
    communications(playerAssessmentId: $playerAssessmentId) {
      ...CommunicationFields
    }
  }

  ${COMMUNICATION_FIELDS}
`;

interface ICommunicationListGraphQL {
  playerAssessmentId: string;
}

const CommunicationListGraphQL: React.FC<ICommunicationListGraphQL> = ({ playerAssessmentId }) => {
  const { data, loading, error } = useQuery(GET_COMMUNICATION_LIST, {
    variables: { playerAssessmentId },
    fetchPolicy: 'cache-and-network'
  });

  const logs: ICommunication[] = (data && data.communications) || [];

  const termLookup = getCompanySportTerminology()
  const sessionTerm = termLookup[SportTerminology.SESSION];
  const emailTypeLabels: { [key: string]: string } = {
    WELCOME: 'Welcome Email',
    SESSION_START: `${sessionTerm} Start`,
    SESSION_UPDATE: `${sessionTerm} Update`,
    SESSION_REMINDER: `${sessionTerm} Reminder`,
    SESSION_CANCELLED: `${sessionTerm} Cancelled`,
    HOLD: `On Hold`,
    REPORT_CARD: 'Report Card',
    LEVEL_ASSIGNMENT: 'Level Assigned',
    TEAM_ASSIGNMENT: 'Team Assigned',
    CONCLUDE: 'Assessment Concluded'
  }

  if (logs.length === 0 && loading) return <Typography>Loading...</Typography>;

  return (
    <List style={{ maxHeight: '400px', overflowY: 'auto' }}>
      {logs.map(log => {
        const date = log.scheduledAt
          ? `Scheduled @ ${dayjs(log.scheduledAt).format('YYYY/MM/DD h:mm A')}`
          :  `Sent @ ${dayjs(log.sentAt).format('YYYY/MM/DD h:mm A')}`;

        return (
          <ListItem key={log._id}>
            <ListItemText
              primary={`${emailTypeLabels[log.type]}`}
              secondary={<span>
                <span>{log.to}</span><br />
                <span>{date}</span>
              </span>}
            />
          </ListItem>
        );
      })}
      {logs.length === 0 && (
        <ListItem>
          <ListItemText primary="None" />
        </ListItem>
      )}
    </List>
  );
};

export default CommunicationListGraphQL;
