import React from 'react';
import styled from 'styled-components';

// Material UI
import Box from '@material-ui/core/Box';
import SignOutIcon from '@material-ui/icons/ExitToApp';
import PlayersIcon from '@material-ui/icons/Group';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DrillsIcon from '@material-ui/icons/Assignment';
import SessionsIcon from '@material-ui/icons/DateRange';
import UpcomingIcon from '@material-ui/icons/Check';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import GearIcon from '@material-ui/icons/Settings';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';

// Data
import { SPORT_TERMINOLOGY } from '../../../../lib/constants';
import logoIcon from '../../../../assets/logo.png'
import meaLogo from '../../../../assets/logo.png';

// Components
import { CollapsibleNavItem } from '../../../_core/_ui/nav.components';
import { Spacer } from '../../../_core/_ui/structure.components';
import { Fab } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { mainColor } from '../../../../lib/theme';
import { Prompt, useHistory } from 'react-router-dom';
import { buildSignOut, getAuthUser } from '../../../../lib/services/auth.service';
import CompanySelectorGraphQL from '../../../Company/CompanySelector/company-selector.graphql';
import { pluralTerm } from '../../../../lib/helpers/term.helper';
import { makeVar, useReactiveVar } from '@apollo/client';
import { hasAnyRoles } from '../../../../lib/services/user.service';
import { isContextCollapsedVar } from './dashboard-sidebar.component';
import { companyLogo, getCompanyHasAutomation, getCompanyLogo, getCompanySportTerminology } from '../../../../lib/services/company.service';
import { getHasUnsavedFormat } from '../../../../lib/services/assessment-stage.service';
import { store } from '../../../../lib/utils/storage';
import { Sport, SportTerminology } from '../../../../lib/types';

const Logo = styled.img`
  width: 136px;
`;

const ToggleFab = withStyles(() => ({
  root: {
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    position: 'absolute',
    right: '-12px',
    top: '35px',
    color: '#fff',
    background: mainColor,
    width: '24px',
    height: '24px',
    minHeight: 'auto'
  }
}))(Fab);

interface IWrapper {
  isCollapsed?: boolean;
}
const Wrapper = styled.div<IWrapper>`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  padding: 8px 0;
  position: fixed;
  height: 100%;
  width: ${props => props.isCollapsed ? 'auto' : '180px'};
`;

export const isNavCollapsedVar = makeVar(false);
const setCollapsed = (nextValue: boolean) => isNavCollapsedVar(nextValue);

const DashboardNav = () => {
  const hasUnsavedFormat = getHasUnsavedFormat();
  const history = useHistory();
  const signOut = buildSignOut();
  const isCollapsed = useReactiveVar(isNavCollapsedVar);
  const hasAutomation = getCompanyHasAutomation();
  const logo = getCompanyLogo();
  const currLogoIcon = logo === meaLogo ? logoIcon : logo;
  const termLookup = getCompanySportTerminology()
  const defaultTermLookup = SPORT_TERMINOLOGY[Sport.default]
  const athletesTerm = '/' + pluralTerm(defaultTermLookup[SportTerminology.ATHLETE]).toLowerCase()
  const eventsTerm = '/' + pluralTerm(defaultTermLookup[SportTerminology.EVENT]).toLowerCase()

  const toggleCollapsed = () => {
    if (isCollapsed) {
      isContextCollapsedVar(false);
    }

    setCollapsed(!isCollapsed);
  }

  return (
    <>
      <Prompt message="You have unsaved changes. Are you sure you want to leave?" when={hasUnsavedFormat} />
      <ToggleFab style={{ position: 'fixed', zIndex: 101, left: isCollapsed ? '52px' : '167px' }} onClick={() => toggleCollapsed()}>
        {isCollapsed
          ? <ArrowRightIcon style={{ position: 'relative', left: '1px', top: '1px' }} />
          : <ArrowLeftIcon style={{ position: 'relative', top: '1px' }} />
        }
      </ToggleFab>
      <Wrapper isCollapsed={isCollapsed}>
        <Box style={{ marginTop: isCollapsed ? '8px' : '0' }} />
        <CollapsibleNavItem icon={isCollapsed && <Logo src={logoIcon} />} light isCollapsed={isCollapsed}>
          <Logo src={meaLogo} />
        </CollapsibleNavItem>

        {hasAnyRoles(['REPORTS', 'CONFIGURATION']) && <CollapsibleNavItem
          isActive={history.location.pathname === '/'}
          isCollapsed={isCollapsed}
          icon={<DashboardIcon />}
          onClick={() => history.push('/')}
        >
          Dashboard
        </CollapsibleNavItem>}

        {hasAnyRoles(['CHECK_IN', 'SCORING']) && <CollapsibleNavItem
          isActive={history.location.pathname === '/upcoming'}
          isCollapsed={isCollapsed}
          icon={<UpcomingIcon />}
          onClick={() => history.push('/upcoming')}
        >
          Evaluate
        </CollapsibleNavItem>}

        {hasAnyRoles(['CONFIGURATION']) && <CollapsibleNavItem
          isActive={history.location.pathname === '/sessions'}
          isCollapsed={isCollapsed}
          icon={<SessionsIcon />}
          onClick={() => history.push('/sessions')}
        >
          {pluralTerm(termLookup[SportTerminology.SESSION])}
        </CollapsibleNavItem>}
        {hasAnyRoles(['CONFIGURATION', 'REPORTS']) && <CollapsibleNavItem
          isActive={history.location.pathname === athletesTerm}
          isCollapsed={isCollapsed}
          icon={<PlayersIcon />}
          onClick={() => history.push(athletesTerm)}
        >
          {pluralTerm(termLookup[SportTerminology.ATHLETE])}
        </CollapsibleNavItem>}
        {hasAnyRoles(['CONFIGURATION']) && <CollapsibleNavItem
          isActive={history.location.pathname === eventsTerm || history.location.pathname === '/practice-plans' || history.location.pathname === '/' + defaultTermLookup[SportTerminology.METRIC].toLowerCase()}
          isCollapsed={isCollapsed}
          icon={<DrillsIcon />}
          onClick={() => history.push(eventsTerm)}
        >
          {pluralTerm(termLookup[SportTerminology.EVENT])}
        </CollapsibleNavItem>}

        {hasAnyRoles(['CONFIGURATION']) && hasAutomation && <CollapsibleNavItem
          isActive={history.location.pathname === '/format'}
          isCollapsed={isCollapsed}
          icon={<GearIcon />}
          onClick={() => history.push('/format')}
        >
          Format
        </CollapsibleNavItem>}



        <Spacer />
        {!isCollapsed && <Box p={2}><CompanySelectorGraphQL /></Box>}
        {hasAnyRoles(['CONFIGURATION']) && <CollapsibleNavItem
          isActive={history.location.pathname === '/settings'}
          isCollapsed={isCollapsed}
          icon={<SettingsApplicationsIcon />}
          onClick={() => history.push('/settings')}
        >
          Settings
        </CollapsibleNavItem>}
        {hasAnyRoles(['USER_MANAGEMENT']) && <CollapsibleNavItem
          isActive={history.location.pathname === '/users'}
          isCollapsed={isCollapsed}
          icon={<PlayersIcon />}
          onClick={() => history.push('/users')}
        >
          Users
        </CollapsibleNavItem>}
        <CollapsibleNavItem
          onClick={async () => await signOut()}
          isCollapsed={isCollapsed}
          icon={<SignOutIcon />}
        >
          Sign Out
        </CollapsibleNavItem>
      </Wrapper>
    </>
  );
};

export default DashboardNav;


// <CollapsibleNavItem
//   isActive={history.location.pathname === '/reports'}
//   isCollapsed={isCollapsed}
//   icon={<ReportsIcon />}
//   onClick={() => history.push('/reports')}
// >
//   Reports
// </CollapsibleNavItem>

// {hasAnyRoles(['CONFIGURATION']) && <CollapsibleNavItem
//   isActive={history.location.pathname === '/weighting'}
//   isCollapsed={isCollapsed}
//   icon={<EqualizerIcon />}
//   onClick={() => history.push('/weighting')}
// >
//   Weighting
// </CollapsibleNavItem>}
