import _ from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';
import { makeVar, useApolloClient, useMutation, useReactiveVar } from '@apollo/client';

import { removeTrackedQuery, trackedQueries } from '../../../lib/utils/tracker.link';
import { UPDATE_HANDLERS } from '../../../lib/services/update-handler.service';

import LoadingPage from '../../_pages/_boilerplate/LoadingPage/loading-page.container';
import { getQueueOpen, isSyncing } from '../PersistGateContainer/persist-gate.container';
import { RECORD_USER_SESSION_ACTION } from '../../Score/ManageEvaluations/manage-evaluations.graphql';
import { store } from '../../../lib/utils/storage';
import { getAuthUser } from '../../../lib/services/auth.service';
import { getAllCurrUsersSessions, removeUserSessionActions, setCurrUserSessions } from '../../../lib/utils/user-session.service';

interface IOfflineHydrator {
  children: ReactNode;
}

let timeout: any;
export const USER_SESSIONS_LOCAL_REF = 'max_UserSessions';
export const QUERY_LOCAL_REF = 'max_TrackedQueries';

export const isSavingScores = makeVar<boolean>(false);
export const getIsSavingScores = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useReactiveVar(isSavingScores);
};

export const shouldRefetchAfterOfflineSync = makeVar<boolean>(false);
export const getShouldRefetchAfterOfflineSync = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useReactiveVar(shouldRefetchAfterOfflineSync);
};


const OfflineHydrator: React.FC<IOfflineHydrator> = ({ children }) => {
  const user = getAuthUser();
  const client = useApolloClient();
  const isQueueOpen = getQueueOpen();
  const [isLoaded, setLoaded] = useState(false);
  const [recordUserSessionActionMutation] = useMutation(RECORD_USER_SESSION_ACTION);

  useEffect(() => {
    const hydrate = async () => {
      const promises: Array<Promise<any>> = [];

      const currQueries = trackedQueries();

      if (!currQueries) {
        try {
          trackedQueries(JSON.parse(await store.get(QUERY_LOCAL_REF) || '').queries || []);
        } catch (e) {
          trackedQueries([]);
        }
      }

      trackedQueries().forEach(trackedQuery => {
        const context = JSON.parse(trackedQuery.contextJSON);
        const query = JSON.parse(trackedQuery.queryJSON);
        const variables = JSON.parse(trackedQuery.variablesJSON);

        promises.push(
          client.mutate({
            context,
            mutation: query,
            optimisticResponse: context.optimisticResponse,
            update: UPDATE_HANDLERS[trackedQuery.name],
            variables
          })
        );

        removeTrackedQuery(trackedQuery.id);
      });

      try {
        if (promises.length) isSyncing(true);
        Promise.all(promises)
          .then(() => {
            if (promises.length) {
              if (timeout) clearTimeout(timeout);
              timeout = setTimeout(() => {
                isSyncing(false);
              }, 3000);
            }
          });
      } catch(e) {
        // ALLOW TRACKED QUERIES TO FAIL
        console.error('Offline mutation hydration failed...', e);
      }

      setLoaded(true);

      if (user) {
        try {
          isSavingScores(true);

          // Loop through all sessions for curr user
          const sessions = await getAllCurrUsersSessions(user)
          const sessionsToDelete: number[] = []
          if (sessions?.length) {
            for (let i = 0; i < sessions.length; i++) {
              const session = sessions[i]

              // Submit all actions for each stored session
              if (session.actions.length) {
                const actionResults = await recordUserSessionActionMutation({variables: session})
                if (actionResults.data.recordUserSession) {
                  const succeeded = actionResults.data.recordUserSession.reduce((curr: number[], a: {succeeded: boolean} | null, aIndex: number) => {
                    if (a?.succeeded) {
                      curr.push(aIndex);
                    }

                    return curr;
                  }, [])

                  // Remove all successful session actions from local storage
                  const succeededActions = session.actions.filter((_, aI) => succeeded.includes(aI))
                  if (succeeded.length) {
                    await removeUserSessionActions(user, session, succeededActions)
                    shouldRefetchAfterOfflineSync(true)
                  }
                }
              }

              // Delete any empty sessions. Any sessions with actions that failed should keep those actions in storage.
              // If last session (ie. curr session) then delete actions only, otherwise delete session if empty
              if (i === sessions.length - 1)
                sessions[i].actions = []
              else sessionsToDelete.push(i)
            }

            await setCurrUserSessions(user, sessions.filter((_, i) => !sessionsToDelete.includes(i)))
          }
        } catch(e) {
          console.error("Failed on score hydration", e);
        } finally {
          isSavingScores(false);
        }
      }
    };

    hydrate();
  }, [isQueueOpen, user]);

  if (!isLoaded) return <LoadingPage />;

  return <>{children}</>;
};

export default OfflineHydrator;
